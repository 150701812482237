<template>
  <v-app>
    <RouterView />

    <!-- register base components here -->
    <VLibraComponent />
    <BaseLoading />
    <BaseSnackBar />

    <WhatsAppButton v-if="hasWhatsAppSupportUrl" />
  </v-app>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import BaseLoading from './components/base/BaseLoading.vue'
import BaseSnackBar from './components/base/BaseSnackBar.vue'
import { useConnectionStatusStore } from './stores/connection'
import VLibraComponent from '@/components/vlibras/VLibraComponent.vue'
import ProviderService from './services/provider.service'
import { useFavicon } from '@vueuse/core'
import { getActiveOrganizationData } from './services/organization.service'
import { useRoleStore } from './stores/role'
import WhatsAppButton from './components/icons/WhatsAppButton.vue'

const providerService = new ProviderService()

const connectionStore = useConnectionStatusStore()
const roleStore = useRoleStore()
const favicon = useFavicon()

window.addEventListener('offline', () => connectionStore.setOffline())
window.addEventListener('online', () => connectionStore.setOnline())

const hasWhatsAppSupportUrl = computed(() => import.meta.env.VITE_WHATSAPP_SUPPORT_URL?.length > 0);

onMounted(async () => {
  const [provider, organization] = await Promise.all([
    providerService.getProviderFromActiveOrganization(),
    getActiveOrganizationData()
  ])

  if (organization.custom_roles_name) {
    roleStore.setCustomRolesName(organization.custom_roles_name)
  }

  if (provider?.configs?.page_title?.length) {
    document.title = provider?.configs?.page_title
  }

  if (provider?.configs?.page_icon?.length) {
    favicon.value = provider?.configs?.page_icon
  }
})
</script>
