import { pt } from 'vuetify/locale'

export default {
  ...pt,
  application: 'Aplicação',
  applications: 'Aplicações',
  createApplication: 'Criar aplicação',
  organization: 'Organização',
  courses: 'Turmas',
  dashboard: 'Painel',
  more: 'Mais',
  forms: 'Formulários',
  exams: 'Avaliações',
  createExam: 'Criar avaliação',
  createExams: 'Criar avaliações',
  createSchool: 'Criar escola',
  createAdmin: 'Adicionar administrador',
  createTeacher: 'Adicionar professor',
  createStudent: 'Adicionar aluno',
  createCourse: 'Criar turma',
  addTeacher: 'Adicionar professor',
  exam: 'Avaliação',
  newExam: 'Nova avaliação',
  login: 'Entrar',
  forgotPassword: 'Esqueci a senha',
  resetPassword: 'Redefinir senha',
  clear: 'Limpar',
  logout: 'Sair',
  email: 'E-mail',
  name: 'Nome',
  password: 'Senha',
  loginWithGoogle: 'Entrar com Google',
  loginWithApple: 'Entrar com Apple',
  loginWithMicrosoft: 'Entrar com Microsoft',
  register: 'Cadastrar',
  input: {
    appendAction: 'Mostrar senha'
  },
  questions: 'Questões',
  me: 'Minhas questões',
  others: 'Banco de questões',
  organizationQuestions: 'Questões da instituição',
  createQuestion: 'Criar questão',
  createFormQuestion: 'Criar pergunta',
  search: 'Pesquisar',
  objective: 'Resposta única',
  multipleChoice: 'Resposta múltipla',
  trueOrFalse: 'Verdadeiro ou falso',
  openEnded: 'Discursiva',
  connectTheDots: 'Ligar os pontos',
  essay: 'Redação',
  matrix: 'Matriz',
  fillInTheBlanks: 'Preencher lacunas',
  sorting: 'Ordenar',
  editForm: 'Atualizar formulário',
  slider: 'Barra de progresso',
  title: 'Título',
  blank: 'Em branco',
  seeAllModels: 'Ver todos os modelos',
  perPage: 'Exibir por página',
  filter: 'Filtro',
  myExams: 'Minhas avaliações',
  myForms: 'Meus formulários',
  untitledExam: 'Avaliação sem título',
  untitledForm: 'Formulário sem título',
  view: 'Visualizar',
  apply: 'Aplicar',
  duplicate: 'Duplicar',
  newForm: 'Novo formulário',
  'new-question': 'Nova questão',
  'exam-application': 'Aplicação de avaliação',
  personalInfos: 'Informações pessoais',
  schoolInfos: 'Informações da escola',
  organizationInfos: 'Informações da instituição',
  schoolInfosDescription:
    'Será exibido nas aplicações realizadas por você e em áreas da plataforma Pruvo.',
  personalInfosDescription: 'Isso será exibido nas aplicações realizadas por você.',
  accessInfos: 'Informações de acesso',
  accessInfosDescription: 'Dados para login e segurança',
  changePassword: 'Trocar senha',
  repeatPassword: 'Repetir senha',
  teachingStages: 'Etapas de ensino',
  teachingStagesDescription:
    'Adicione ou remova as etapas de ensino da sua instituição no campo ao lado.',
  add: 'Adicionar',
  schoolGrades: 'Anos escolares',
  schoolGradesDescription:
    'Adicione ou remova as anos escolares da sua instituição no campo ao lado.',
  shifts: 'Turnos',
  shiftsDescription: 'Adicione ou remova os turnos da sua instituição no campo ao lado.',
  untitledQuestion: 'Questão sem título',
  myApplications: 'Minhas aplicações',
  organizationInfo: 'Instituição',
  schools: 'Escolas',
  admins: 'Administradores',
  school: 'Escola',
  teachers: 'Professores',
  administration: 'Administração',
  course: 'Turma',
  addCourse: 'Adicionar turmas',
  import: 'Importar',
  students: 'Alunos',
  teacher: 'Professor',
  editQuestion: 'Editar questão',
  copyToMyQuestions: 'Copiar para minhas questões',
  delete: 'Excluir',
  student: 'Aluno',
  showApplicationsFromForms: 'Ver aplicações do formulário',
  exportToForms: 'Exportar para Google Forms',
  save: 'Salvar',
  publish: 'Publicar',
  saveAndApply: 'Salvar e aplicar',
  dashboardInfo: 'Dashboard',
  myPlan: 'Meu plano',
  executedAplications: 'Aplicações executadas',
  configs: 'Configurações',
  classroom: 'Google Sala de Aula',
  classroomDescription:
    'Defina a conta Google para sincronização das turmas do Google Sala de Aula.',
  print: 'Imprimir',
  'exam-details': 'Detalhes da avaliação',
  versionHistory: 'Histórico de versões',
  preview: 'Visualizar',
  schoolsStudentLogin: 'Logar aluno com QR Code',
  coursesStudentLogin: 'Logar aluno com QR Code',
  studentsLogin: 'Logar aluno com QR Code',
  examsModel: 'Modelos de avaliação',
  saveAsModel: 'Salvar como modelo de avaliação',
  saveModel: 'Salvar modelo',
  'exam-model': 'Editar modelo',
  edit: 'Editar',
  knowledgeAreaDescription:
    'Adicione ou remova as áreas de conhecimento da sua instituição no campo ao lado.',
  knowledgeAreas: 'Áreas de conhecimento',
  curricularComponentDescription:
    'Adicione ou remova os componentes curriculares da sua instituição no campo ao lado.',
  curricularComponents: 'Componentes curriculares',
  results: 'Resultados',
  'support-material': 'Material de apoio',
  'support-material-student': 'Materiais de apoio - Aluno',
  'support-material-teacher': 'Materiais de apoio - Professor',
  'support-material-student-monitoring': 'Materiais de apoio - Ficha de acompanhamento do aluno',
  'support-material-coordinator': 'Materiais de apoio - Coordenador',
  impersonate: 'Personificar',
  'class-plans': 'Planos de aula'
}
