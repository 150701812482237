import { createRouter, createWebHistory } from 'vue-router'
import Environment from '@/config/environment'
import { useLoadingStore } from '@/stores/loading'
import { useSidebarStore } from '@/stores/sidebar'
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { getUserData } from '@/services/user.service'
import { setActiveOrganization } from '@/services/organization.service'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth/login',
      name: 'login',
      meta: {
        isForDev: true
      },
      component: () => import('../views/auth/LoginDevView.vue')
    },
    {
      path: '/auth/check-status',
      name: 'auth',
      component: () => import('../views/auth/AuthView.vue')
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('../views/organization/OrganizationListView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/',
      component: () => import('../views/layout/MainLayout.vue'),
      redirect: '/organization-info',
      meta: { requiresAuth: true },
      children: [
        {
          path: '/applications/:id/edit',
          name: 'application-edit',
          component: () => import('../views/application/ApplicationEditView.vue')
        },
        {
          path: '/applications/:id/view',
          name: 'application-view',
          component: () => import('../views/application/ApplicationView.vue')
        },
        {
          path: '/forms',
          name: 'forms',
          component: () => import('../views/form/FormListView.vue')
        },
        {
          path: '/new-form',
          name: 'newForm',
          component: () => import('../views/form/NewFormView.vue')
        },
        {
          path: '/edit-form/:id',
          name: 'editForm',
          component: () => import('../views/form/EditFormView.vue')
        },
        {
          path: '/application-form/:id',
          name: 'applicationForm',
          component: () => import('../views/application/ApplicationFormView.vue')
        },
        {
          path: '/exams',
          name: 'exams',
          component: () => import('../views/exam/ExamListView.vue')
        },
        {
          path: '/exams/new',
          name: 'newExam',
          component: () => import('../views/exam/NewExamView.vue')
        },
        {
          path: '/exams/:id/edit',
          name: 'exam',
          component: () => import('../views/exam/ExamView.vue')
        },
        {
          path: '/exams/:id/edit-model',
          name: 'exam-model',
          component: () => import('../views/exam/ExamViewModel.vue')
        },
        {
          path: '/exams/:id/details',
          name: 'exam-details',
          component: () => import('../views/exam/ExamDetailView.vue')
        },
        {
          path: '/exams/:id/application',
          name: 'exam-application',
          component: () => import('../views/application/ApplicationCreateView.vue')
        },
        {
          path: '/questions',
          name: 'questions',
          component: () => import('../views/question/QuestionListView.vue')
        },
        {
          path: '/questions/:id/edit',
          name: 'editQuestion',
          component: () => import('../views/question/EditQuestionView.vue')
        },
        {
          path: '/new-question',
          name: 'new-question',
          component: () => import('../views/question/CreateQuestionView.vue')
        },
        {
          path: '/organization-profile',
          name: 'Perfil',
          component: () => import('../views/organization/OrganizationProfileView.vue')
        },
        {
          path: '/organization-info',
          name: 'organizationInfo',
          component: () => import('../views/organization/OrganizationInfoView.vue')
        },
        {
          path: '/school-info/:id',
          name: 'school',
          component: () => import('../views/school/SchoolInfoView.vue')
        },
        {
          path: '/school-info/:schoolId/course-info/:courseId',
          name: 'course',
          component: () => import('../views/course/CourseInfoView.vue')
        },
        {
          path: '/school-info/:schoolId/teacher-info/:teacherUserId',
          name: 'teacher',
          component: () => import('../views/teacher/TeacherInfoView.vue')
        },
        {
          path: '/school-info/:schoolId/course-info/:courseId/student-info/:studentUserId',
          name: 'student',
          component: () => import('../views/student/StudentInfoView.vue')
        },
        {
          path: '/dashboards',
          name: 'dashboardInfo',
          component: () => import('../views/dashboard/DashboardView.vue')
        },
        {
          path: '/schools-student-login',
          name: 'schoolsStudentLogin',
          component: () => import('../views/student-login/SchoolsStudentsLogin.vue')
        },
        {
          path: '/schools-student-login/:id/courses-student-login',
          name: 'coursesStudentLogin',
          component: () => import('../views/student-login/CoursesStudentsLogin.vue')
        },
        {
          path: '/schools-student-login/:schoolId/courses-student-login/:courseId/students',
          name: 'studentsLogin',
          component: () => import('../views/student-login/StudentsLogin.vue')
        },
        {
          path: '/support-material',
          name: 'support-material',
          component: () => import('../views/support-material/ListMaterial.vue')
        },
        {
          path: '/class-plans',
          name: 'class-plans',
          component: () => import('@/views/class-plans/ClassPlansList.vue')
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.beforeEach(async (to) => {
  useLoadingStore().startLoading()

  const auth = getAuth()

  const checkAuthState = () => {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        resolve(user)
      })
    })
  }

  const user = await checkAuthState()

  if (to?.query?.customToken) {
    try {
      await signInWithCustomToken(getAuth(), to.query.customToken as string)
      const user = (await getUserData())?.data()
      if (!user?.active_organization?.id) {
        await router.push({ name: 'organizations' })
        return
      }
      await setActiveOrganization(user?.active_organization?.id)
      window.location.href = `${window.location.href?.split('?')?.[0]}${
        to.query.sidebar ? `?sidebar=${to.query.sidebar}` : ''
      }`
    } catch {
      location.href = Environment.LOGIN_URL
    }
  }

  const { requiresAuth, isForDev } = to.meta

  if (isForDev && Environment.VITE_ENVIRONMENT !== 'dev') {
    location.href = Environment.LOGIN_URL
  }

  if (requiresAuth && !user) {
    location.href = Environment.LOGIN_URL
  }
})

router.afterEach((to) => {
  window.scrollTo(0, 0)
  useLoadingStore().stopLoading()
  if (to?.query?.sidebar?.length) {
    useSidebarStore().showCollapsable(to.query.sidebar as string)
  }
})

export default router
