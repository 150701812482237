import {
  collection,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  where
} from 'firebase/firestore'
import BaseService from './base.service'
import type { DocumentData } from 'firebase/firestore/lite'
import type IProvider from '@/interfaces/providers/provider.interface'
import { getAuth } from 'firebase/auth'
import { db } from '@/config/firebase'

export default class ProviderService extends BaseService {
  public async getProviderFromActiveOrganization(): Promise<IProvider | null> {
    const providerRef = (await this.getActiveOrganizationData())?.provider_ref as DocumentReference
    if (!providerRef) return null
    const snapshot = await getDoc(providerRef)
    return snapshot?.data() as IProvider
  }

  public async getProviderIdFromActiveOrganization() {
    const { provider_id } = (await this.getActiveOrganizationData()) as DocumentData

    return provider_id
  }

  public async hasUserPermissionOnProvider(permission: string) {
    const [providerAcls, person] = await Promise.all([
      this.getProviderAcls(),
      this.getAuthUserOnProvider()
    ])

    if (!providerAcls || !person) return false

    return providerAcls.some(
      (acl) =>
        acl?.level === person?.level &&
        acl?.permissions?.map((p: { name: string }) => p?.name)?.includes(permission)
    )
  }

  private async getProviderAcls() {
    const provider = await this.getProviderFromActiveOrganization()
    return (
      await getDocs(query(collection(db, 'providers', provider?.name as string, 'acls')))
    )?.docs?.map((doc) => doc?.data())
  }

  private async getAuthUserOnProvider() {
    const provider = await this.getProviderFromActiveOrganization()
    return (
      await getDocs(
        query(
          collection(db, 'providers', provider?.name as string, 'people'),
          where('email', '==', getAuth()?.currentUser?.email),
          limit(1)
        )
      )
    )?.docs?.[0]?.data()
  }
}
