import { defineStore } from 'pinia'

export const useRoleStore = defineStore('Role', {
  state: () => ({
    loaded: false,
    customRolesName: {
      1: 'Aluno',
      10: 'Professor',
      20: 'Coordenador',
      30: 'Diretor',
      40: 'Técnico',
      50: 'Financeiro',
      60: 'Coordenador pedagógico',
      70: 'Administrador'
    } as { [key: number]: string }
  }),
  actions: {
    setLoaded(loaded: boolean) {
      this.loaded = loaded
    },
    setCustomRolesName(customRolesName: any) {
      this.customRolesName = {
        ...this.customRolesName,
        ...customRolesName
      }
    }
  },
  getters: {
    getCustomRole: (state) => (role: number) => {
      return state.customRolesName[role]
    }
  }
})
